/* HTML: <div class="loader"></div> */
.asset-loading {
    height: 15px;
    aspect-ratio: 4;
    --_g: no-repeat radial-gradient(farthest-side,#000 90%,#0000);
    background: 
      var(--_g) left, 
      var(--_g) right;
    background-size: 25% 100%;
    display: grid;
  }
  .asset-loading:before,
  .asset-loading:after {
    content: "";
    height: inherit;
    aspect-ratio: 1;
    grid-area: 1/1;
    margin: auto;
    border-radius: 50%;
    transform-origin: -100% 50%;
    background: #000;
    animation: l49 1s infinite linear;
  }
  .asset-loading:after {
    transform-origin: 200% 50%;
    --s:-1;
    animation-delay: -.5s;
  }
  
  @keyframes l49 {
    58%,
    100% {transform: rotate(calc(var(--s,1)*1turn))}
  }


.field-set-animation-container {
  position: relative;
  width: 70%;
}

.field-set-animation-container input::placeholder {
  opacity: 0;
}

.field-set-animation-container input {
  width: 100%;
  background-color: transparent;
  height: 45px;
  font-size: 1rem;
  padding: 6px 8px;
  outline: none;
  border: 1px solid gray;
  box-sizing: border-box;
  border-radius: 5px;
}

.field-set-animation-container label {
  position: absolute;
  left: 10px;
  top: 50%;
  transform: translateY(-50%);
  font-size: 1rem;
  color: #757575;
  letter-spacing: 1px;
  transition: 0.3s;
  font-family: "Roboto", sans-serif;
  font-weight: 500;
}

.field-set-animation-container input:focus + label,
.field-set-animation-container input:not(:placeholder-shown) + label {
  top: 0;
  font-size: 0.75rem;
  color: #8d8d8d;
  background: #fff;
  padding: 2px 7px;
}

.field-set-animation-container input:focus {
  border: 2px solid #8d8d8d;
}

.switch {
  --_switch-height: 24px;
  --_switch-width: 48px;

  position: relative;
  margin-right: 0.5em;
  height: var(--_switch-height);
  width: var(--_switch-width);

  /* Hide default HTML checkbox but preserve accessibility. */
  & input {
    opacity: 0;
    width: 0;
    height: 0;
  }
}

/* The slider */
.slider {
  --outline-width: 1px;

  position: absolute;
  inset: 0;

  background-color: var(--primary-dark-light);
  border-radius: 9999px;
  outline: 1px solid #757575;
  cursor: pointer;

  &::before {
    --_ratio: 80%;
    --_offset: 2px;

    content: "";

    position: absolute;
    top: 50%;
    left: var(--_offset);
    right: unset;
    translate: 0 -50%;

    height: var(--_ratio);
    aspect-ratio: 1 / 1;

    background-color: rgba(117 117 117 / 0.85);
    border-radius: 50%;
  }
  .switch input:checked + & {
    background-color: #004289;
    filter: brightness(130%);
    transition: background-color 150ms ease-out;
  }
  .switch input:checked + &::before {
    left: 0;
    background-color: rgba(245 245 245 / 0.85);
    translate: calc(calc(var(--_switch-width) - 100%) - var(--_offset)) -50%;
  }

  @media (prefers-reduced-motion: no-preference) {
    transition: background-color 150ms ease-in;
    .switch input:checked + & {
      transition: background-color 150ms ease-out;
    }
    .switch input:checked + &::before {
      transition: translate 150ms ease-out;
    }
  }
}

.switch-status {
  color: whitesmoke;
  opacity: 0.85;

  .switch:has(#switch-toggle[checked]) + & {
    opacity: 1;
    font-weight: 500;
  }
}
