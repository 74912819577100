@tailwind base;
@tailwind components;
@tailwind utilities;

html,
body,
#root {
  width: 100%;
  height: 100%;
  padding: 0;
  margin: 0;
  font-family: 'Nunito Sans';
  user-select: none;
  -webkit-perspective: 0px !important; 
  -webkit-backface-visibility: visible !important;
}



#assetManagerPopup {
  display: none !important;
}

.hide-scrollbar-x {
  overflow-x: scroll;
  overflow-y: hidden;
  -ms-overflow-style: none;
  scrollbar-width: none;
}

.hide-scrollbar-x::-webkit-scrollbar {
  width: 0px;
  height: 0px;
}

.menu::-webkit-scrollbar {
  width: 0px;
}

#custom-scrollbar::-webkit-scrollbar-track {
  background: #D9D9D9; 
  border-radius: 10px;
}
 

#custom-scrollbar::-webkit-scrollbar-thumb {
  background: #000; 
  border-radius: 10px;
}

#custom-scrollbar::-webkit-scrollbar {
  width: 4px; 
}

@media (min-width: 500px) and (max-width: 768px) {
  #loader {
    width: 10% ;
  }
}

@media screen and (-webkit-min-device-pixel-ratio: 2), screen and (min-resolution: 192dpi) {
  img {
    image-rendering: crisp-edges;
  }
}
